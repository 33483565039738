import axios from './baseService/axiosInstance.service';
const URL = process.env.REACT_APP_API_URL;

export const candidateLogin = async (body: any, schoolId: any) => {
  const data = await axios.post(`${URL}/candidate-signin?schoolId=${schoolId}`, body);
  return data;
};

export const getCandidateProfileById = async (id: any, schoolId: any) => {
  const data = await axios.get(`${URL}/applicant/${id}?schoolId=${schoolId}`);
  return data;
};

export const updateCandidateAcademicYearGrade = async (
  applicationId: any,
  schoolId: any,
  body: any
) => {
  const data = await axios.put(
    `${URL}/applicant-academic-year-grade/${applicationId}?schoolId=${schoolId}`,
    body
  );
  return data;
};

export const getCandidateFeeById = async (id: any, schoolId: any) => {
  const data = await axios.get(`${URL}/applicant-admission-fee/${id}?schoolId=${schoolId}`);
  return data;
};

export const getNotificationCount = async (id: any) => {
  const data = await axios.get(`${URL}/notification/${id}?`);
  return data;
};

export const postNotificationRead = async (body: any) => {
  const data = await axios.post(`${URL}/notification/read-all`, body);
  return data;
};

export const sendEmailWithCustomTemplate = async (body: any) => {
  return await axios.post(`${URL}/send-custom-mail`, body);
};

export const deleteCandidateApplications = async (body: {candidateApplicationIds : { id: number }[]}) => {
  return await axios.post(`${URL}/delete/applicants`, body);
};
