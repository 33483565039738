// routes
import { PATH_ADMIN, PATH_CANDIDATE, PATH_SUPER_ADMIN } from '../../routes/paths';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
// import NotificationsNoneI from '@mui/icons-material/NotificationsNone';
import Iconify from 'components/Iconify';

export type NavItemType = {
  title: string;
  path: string;
  icon: JSX.Element;
};

export type SidebarItems = NavItemType & {
  children?: NavItemType[];
};

export type SidebarConfigType = {
  subheader: string;
  items: SidebarItems[];
};

export const adminSidebarConfig: SidebarConfigType[] = [
  {
    subheader: 'Admin',
    items: [
      {
        title: 'dashboard',
        path: PATH_ADMIN.admin.dashboard,
        icon: <DashboardOutlinedIcon />
      },
      {
        title: 'Basic setup',
        path: PATH_ADMIN.admin.schoolInformation,
        icon: <InfoOutlinedIcon />,
        children: [
          {
            title: 'school_setup', //school_information
            path: PATH_ADMIN.admin.schoolInformation,
            icon: <InfoOutlinedIcon />
          },
          {
            title: 'Academic Year Grade Setup',
            path: PATH_ADMIN.admin.academicYearGrade.list,
            icon: <SettingsOutlinedIcon />
          },
          {
            title: 'Fee Setup',
            path: PATH_ADMIN.admin.feeStructure,
            icon: <PaidOutlinedIcon />
          },
          {
            title: 'form_setup', //configure_form
            path: PATH_ADMIN.admin.adminConfigForm,
            icon: <StickyNote2OutlinedIcon />
          },
          {
            title: 'Addmission Form',
            path: PATH_ADMIN.admin.admissionForm.list,
            icon: <FormatListBulletedOutlinedIcon />
          },
          {
            title: 'notification_template',
            path: PATH_ADMIN.admin.notificationTemplate,
            icon: <NotificationsNoneIcon />
          },
          {
            title: 'Round Setup',
            path: PATH_ADMIN.admin.round.list,
            icon: (
              <Iconify sx={{ fontSize: '1.4rem' }} icon={'healthicons:i-exam-multiple-choice'} />
            )
          },
          {
            title: 'Transactions',
            path: PATH_ADMIN.admin.transaction.list,
            icon: <Iconify sx={{ fontSize: '1.4rem' }} icon={'icon-park-outline:transaction'} />
          },
          {
            title: 'Invoice Template',
            path: PATH_ADMIN.admin.invoiceTemplate.root,
            icon: <Iconify sx={{ fontSize: '1.4rem' }} icon={'fluent:mail-template-16-regular'} />
          },
          {
            title: 'Template Editor',
            path: PATH_ADMIN.admin.templateEditor.viewTemplateEditor,
            icon: <Iconify sx={{ fontSize: '1.4rem' }} icon={'gala:editor'} />
          }
        ]
      }
    ]
  },
  {
    subheader: 'Applications',
    items: [
      {
        title: 'applicant_list',
        path: PATH_ADMIN.admin.applicationList,
        icon: <FormatListBulletedOutlinedIcon />
      },
      {
        title: 'favorites',
        path: PATH_ADMIN.admin.favourites,
        icon: <StarBorderOutlinedIcon />
      },
      {
        title: 'communications',
        path: PATH_ADMIN.admin.chat,
        icon: <ChatOutlinedIcon />
      }
    ]
  },
  {
    subheader: 'Rounds',
    items: [
      {
        title: 'Round Publish', //Academic Year Grade Round Setup
        path: PATH_ADMIN.admin.academicYearGradeRound.list,
        icon: <SettingsOutlinedIcon />
      },
      {
        title: 'Round Evaluation',
        path: PATH_ADMIN.admin.round.evaluation.list,
        icon: <Iconify sx={{ fontSize: '1.4rem' }} icon={'healthicons:i-exam-multiple-choice'} />
      }
    ]
  },
  {
    subheader: 'Sub Admin',
    items: [
      {
        title: 'sub_admin',
        path: PATH_ADMIN.admin.subAdminList,
        icon: <FormatListBulletedOutlinedIcon />
      }
    ]
  }
];

export const candidateSidebarConfig = [
  {
    subheader: 'Applicant',
    items: [
      {
        title: 'dashboard',
        path: PATH_CANDIDATE.candidate.candidateDashboard,
        icon: <DashboardOutlinedIcon />
      },
      {
        title: 'admission_form',
        path: PATH_CANDIDATE.candidate.admissionForm,
        icon: <StickyNote2OutlinedIcon />
      },
      {
        title: 'communications',
        path: PATH_CANDIDATE.candidate.candidateChat,
        icon: <ChatOutlinedIcon />
      }
    ]
  }
];

export const superAdminSidebarConfig = [
  {
    subheader: 'Super-Admin',
    items: [
      {
        title: 'dashboard',
        path: PATH_SUPER_ADMIN.superAdmin.dashboard,
        icon: <DashboardOutlinedIcon />
      },
      {
        title: 'school_list',
        path: PATH_SUPER_ADMIN.superAdmin.schoolList,
        icon: <FormatListBulletedOutlinedIcon />
      },
      {
        title: 'admin_list',
        path: PATH_SUPER_ADMIN.superAdmin.adminList,
        icon: <FormatListBulletedOutlinedIcon />
      }
    ]
  }
];

export const subAdminSidebarConfig = [
  {
    subheader: 'Sub-Admin',
    items: [
      {
        title: 'dashboard',
        path: PATH_ADMIN.admin.dashboard,
        icon: <DashboardOutlinedIcon />
      },
      {
        title: 'school_setup', //school_information
        path: PATH_ADMIN.admin.schoolInformation,
        icon: <InfoOutlinedIcon />
      },
      // {
      //   title: 'academic_year_setup', //school_setup
      //   path: PATH_ADMIN.admin.schoolSetup,
      //   icon: <SettingsOutlinedIcon />
      // },
      {
        // title: 'round_setup', //school_setup
        title: 'Round Setup',
        path: PATH_ADMIN.admin.round.list,
        icon: <Iconify sx={{ fontSize: '1.4rem' }} icon={'healthicons:i-exam-multiple-choice'} />
      },
      {
        // title: 'round_list', //school_list
        title: 'Round Evaluation',
        path: PATH_ADMIN.admin.round.evaluation.list,
        icon: <Iconify sx={{ fontSize: '1.4rem' }} icon={'healthicons:i-exam-multiple-choice'} />
      },
      {
        // title: 'academic_year_grade_setup', //academic_year_grade_setup
        title: 'Academic Year Grade Setup',
        path: PATH_ADMIN.admin.academicYearGrade.list,
        icon: <SettingsOutlinedIcon />
      },
      {
        // title: 'academic_year_grade_round_setup', //academic_year_grade_round_setup
        title: 'Academic Year Grade Round Setup',
        path: PATH_ADMIN.admin.academicYearGradeRound.list,
        icon: <SettingsOutlinedIcon />
      },
      {
        title: 'application_setup', //fee_structure
        path: PATH_ADMIN.admin.feeStructure,
        icon: <PaidOutlinedIcon />
      },
      {
        title: 'form_setup', //configure_form
        path: PATH_ADMIN.admin.adminConfigForm,
        icon: <StickyNote2OutlinedIcon />
      },
      {
        title: 'notification_template', //configure_form
        path: PATH_ADMIN.admin.notificationTemplate,
        icon: <NotificationsNoneIcon />
      },
      {
        title: 'applicant_list',
        path: PATH_ADMIN.admin.applicationList,
        icon: <FormatListBulletedOutlinedIcon />
      },
      {
        title: 'favorites',
        path: PATH_ADMIN.admin.favourites,
        icon: <StarBorderOutlinedIcon />
      },
      {
        title: 'communications',
        path: PATH_ADMIN.admin.chat,
        icon: <ChatOutlinedIcon />
      }
    ]
  }
];
