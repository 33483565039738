import { useRef, useState } from 'react';
// material
import { alpha } from '@mui/material/styles';
import { Avatar, Button, Box, Divider, Typography } from '@mui/material';
// components
import { MIconButton } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';
import { useNavigate } from 'react-router-dom';
import jsCookie from 'js-cookie';
import { useSelector } from 'react-redux';
// ----------------------------------------------------------------------

// const MENU_OPTIONS = [
//   { label: 'Home', icon: homeFill, linkTo: '/' },
//   { label: 'Profile', icon: personFill, linkTo: '#' },
//   { label: 'Settings', icon: settings2Fill, linkTo: '#' }
// ];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // const user: any = localStorage.getItem('user');
  const userObj: any = useSelector((state: any) => state.user);
  // const userObj = JSON.parse(user);
  const i18NextLanguage = jsCookie.get('i18next');

  const handleLogout = () => {
    const user: any = localStorage.getItem('role');
    const schoolName = localStorage.getItem('schoolName');
    const schoolId: any = localStorage.getItem('schoolId');
    if (user !== 'candidate') {
      localStorage.clear();
      navigate('/admin/login');
    } else {
      localStorage.clear();
      localStorage.setItem('schoolId', schoolId);
      navigate(`${schoolName}/login`);
    }
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar alt="My Avatar" src="/static/mock-images/avatars/avatar_default.jpg" />
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {userObj?.role !== 'candidate'
              ? userObj?.role?.toUpperCase() ?? 'N/A'
              : userObj?.applicationNumber?.toUpperCase() ?? 'N/A'}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userObj?.email ?? 'N/A'}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {/* {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))} */}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button onClick={handleLogout} fullWidth color="inherit" variant="outlined">
            {/* Logout */}
            {i18NextLanguage === 'ar'
              ? 'تسجيل الخروج'
              : i18NextLanguage === 'en'
              ? 'Logout'
              : i18NextLanguage === 'fr'
              ? 'Se déconnecter'
              : i18NextLanguage === 'es'
              ? 'Cerrar sesión'
              : 'Logout'}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
